<template>
  <div class="container">
    <div class="px-4">
      <h1>Donations</h1>
      <Alert :variant="alertVariant" v-if="alertMessage">{{ alertMessage }}</Alert>
      <div class="flex mb-6 mt-4">
        <GenericSearch :value="search" @updateSearch="updateSearch" class="mr-5 mt-6" />
        <DateRangeSelect showLabel size="compact" @startDate="startDateFilter" @endDate="endDateFilter" />
        <span class="flex justify-end ml-auto mt-6">
          <LoadingButton variant="outline" @onClick="downloadReport" :loading="downloadingReport" class="mr-4">
            <FontAwesomeIcon class="text-sm" :icon="['far', 'arrow-down-to-line']" v-if="!downloadingReport" />
            <p class="mb-0 ml-4 xs:hidden text-sm sm:block">Download</p>
          </LoadingButton>
        </span>
      </div>
    </div>
    <div class="overflow-x-hidden px-4">
      <div class="table-overflow">
        <GenericTable
          :columns="columns"
          :data="data"
          :actions="true"
          :loading="loading"
          :pagination="pagination"
          :totalName="totalName"
          stacked
          @updateCurrentPage="updateCurrentPage"
          @sort="sort"
        >
          <template #id="{ row }">
            <span v-b-tooltip.hover="row.uuid" class="dashline">
              {{ row.id }}
            </span>
          </template>
          <template #createdAt="{ row }">
            <DateTooltip :date="row.createdAt" />
          </template>
          <template #amount="{ row }">
            {{ row.amount }}
          </template>
          <template #status="{ row }">
            <b-badge variant="success" v-if="row.status === 'ACTIVE'">Active</b-badge>
            <b-badge variant="secondary" v-else-if="row.status === 'INACTIVE'">Inactive</b-badge>
            <b-badge variant="light" v-else-if="row.status === 'PENDING'">Pending</b-badge>
            <b-badge variant="dark" v-else-if="row.status === 'VOID'">Void</b-badge>
            <b-badge variant="danger" v-else-if="row.status === 'FAILED'">Error</b-badge>
          </template>
          <template #actions="{ row }">
            <VoidDonationModal
              v-if="row.status === 'ACTIVE' || row.status === 'PENDING'"
              :donation="row"
              @success="voidSuccess"
            />
          </template>
        </GenericTable>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import Alert from '@/components/ui/Alert.vue';
import GenericSearch from '@/components/GenericSearch.vue';
import GenericTable from '@/components/GenericTable.vue';
import VoidDonationModal from '@/components/modals/VoidDonationModal.vue';
import DateTooltip from '@/components/DateTooltip.vue';
import DateRangeSelect from '@/components/inputs/DateRangeSelect.vue';
import DonationserviceV2 from '@/lib/donation-service-v2';
import LoadingButton from '@/components/ui/LoadingButton.vue';
import { isStripePurchaseTimedOut, setStripePurchaseTimedOutError } from '@/lib/stripe-purchase-timeout';
import { downloadFormattedCSV } from '@/lib/download-file';

export default {
  name: 'Donations',
  components: {
    Alert,
    GenericSearch,
    GenericTable,
    DateTooltip,
    VoidDonationModal,

    DateRangeSelect,
    LoadingButton
  },
  async mounted() {
    this.getDonations();
  },
  data() {
    return {
      columns: [
        {
          name: 'id',
          label: 'ID',
          classes: 'w-auto'
        },
        {
          name: 'createdAt',
          label: 'Created at',
          classes: 'w-auto'
        },
        {
          name: 'name',
          label: 'Name',
          classes: 'w-auto'
        },
        {
          name: 'email',
          label: 'Email',
          classes: 'w-auto'
        },
        {
          name: 'address',
          label: 'Address',
          classes: 'w-auto'
        },
        {
          name: 'state',
          label: 'Province',
          classes: 'w-auto'
        },
        {
          name: 'postal',
          label: 'Postal Code',
          classes: 'w-auto'
        },
        {
          name: 'amount',
          label: 'Amount',
          classes: 'w-auto',
          isCurrency: true
        },
        {
          name: 'status',
          label: 'Status',
          classes: 'w-auto'
        }
      ],
      data: [],
      search: null,
      sortBy: 'createdAt',
      sortDir: 'desc',
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      alertMessage: null,
      alertVariant: 'red',
      totalName: 'Donations',
      routeQuery: false,
      canCreateCashSales: false,
      toggleValue: true,
      showDevices: false,
      loading: true,
      downloadingReport: false,
      error: null,
      endDateFilterValue: null,
      startDateFilterValue: null
    };
  },
  methods: {
    startDateFilter(startDate) {
      this.startDateFilterValue = new Date(startDate).toISOString();

      if (this.endDateFilterValue) {
        this.updateCurrentPage(0);
        this.getDonations();
      }
    },
    async endDateFilter(endDate) {
      this.endDateFilterValue = new Date(endDate).toISOString();

      if (this.startDateFilterValue) {
        await this.updateCurrentPage(0);
        this.getDonations();
      }
    },
    async updateSearch(search) {
      this.search = search;
      await this.updateCurrentPage(0);
      await this.getDonations();
    },
    async getDonations() {
      try {
        this.loading = true;
        const sessionUser = await getAuth().sessionUser();
        const organizationId = this.isRbAdmin ? undefined : sessionUser.organizationUuid;

        const params = {
          organizationId,
          search: this.search,
          fromDate: this.startDateFilterValue,
          toDate: this.endDateFilterValue,
          page: this.currentPage,
          pageSize: this.pageSize,
          sortDir: this.sortDir,
          sortBy: this.sortBy
        };

        const { data, pagination } = await DonationserviceV2.listDonations(params);
        this.pagination = pagination;
        data.forEach((donation) => {
          // if pending and old show as error
          if (isStripePurchaseTimedOut(donation)) {
            setStripePurchaseTimedOutError(donation);
          }
        });
        this.data = data.map((donation) => {
          return {
            ...donation,
            id: this.formatUuid(donation.id),
            createdAt: donation.createdAt,
            name: donation.firstName + ' ' + donation.lastName,
            email: donation.email,
            phone: this.formatTelephone(donation.phone),
            amount: this.formatCurrency(donation.amountCents / 100, { precision: 0 }),
            amountCents: donation.amountCents,
            status: donation.status,
            uuid: donation.id,
            currency: donation.currency?.toUpperCase()
          };
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.alertMessage = `Failed to load donations: ${error.message}`;
        this.alertVariant = 'red';
        return [];
      }
    },
    formatTelephone(val) {
      if (val !== null) {
        return this.formatPhone(val);
      }

      return '';
    },
    async voidSuccess() {
      this.alertMessage = 'Donation Voided Successfully!';
      this.alertVariant = 'green';
      await this.getDonations();
    },
    async updateCurrentPage(page) {
      this.currentPage = page;
      await this.getDonations();
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getDonations();
    },
    async downloadReport() {
      this.downloadingReport = true;
      try {
        const organizationId = (await getAuth().sessionUser()).organizationUuid;
        const data = await DonationserviceV2.donationsReport(
          { organizationId, fromDate: this.startDateFilterValue, toDate: this.endDateFilterValue },
          'text/csv'
        );
        downloadFormattedCSV('donations-report.csv', data);
      } catch (error) {
        this.error = this.parseError(error).message;
      }
      this.downloadingReport = false;
    }
  }
};
</script>

<style scoped>
.dashline {
  border-bottom: 1px dashed;
}
</style>
