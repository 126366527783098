<template>
  <div>
    <span v-b-tooltip.hover :title="title" :class="{ dashline: date }">
      {{ date ? formatDateTime(parseISO(date)) : '-' }}
    </span>
  </div>
</template>
<script>
export default {
  props: ['date'],
  data() {
    return {
      title: null,
      class: null
    };
  },
  mounted() {
    if (this.date) {
      this.title = this.formatDateTime(this.parseISO(this.date), {
        format: 'dateTime',
        timeZone: this.sessionUserTimeZone,
        displayTimeZone: true
      });
    }
  }
};
</script>
<style scoped>
.dashline {
  border-bottom: 1px dashed;
}
</style>
