import { isBefore, addMinutes } from 'date-fns';

export const setStripePurchaseTimedOutError = (order) => {
  order.status = 'FAILED';
  order.errorMessage = 'Purchase timeout';
  order.errorCode = 'incomplete';
};

export const isStripePurchaseTimedOut = (order) => {
  return (
    order.status === 'PENDING' &&
    order.paid !== true &&
    order.merchant === 'stripe_connect' &&
    isBefore(addMinutes(new Date(order.createdAt), 2), new Date())
  );
};
