<template>
  <div class="date-select" :class="{ compact: displaySize }">
    <div class="date-select__start-date">
      <div class="date-select__label" :class="{ 'sr-only': !showLabel }">From</div>
      <b-form-datepicker
        id="from"
        placeholder="No date selected"
        reset-button
        value-as-date
        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
        v-model="startDate"
      />
    </div>
    <div class="date-select__end-date">
      <div class="date-select__label" :class="{ 'sr-only': !showLabel }">To</div>
      <b-form-datepicker
        id="to"
        placeholder="No date selected"
        :min="startDate"
        reset-button
        value-as-date
        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
        v-model="endDate"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    defaultStartDate: {
      type: [String, Date],
      default: null
    },
    defaultEndDate: {
      type: [String, Date],
      default: null
    }
  },
  computed: {
    displaySize() {
      return this.size && this.size === 'compact' ? true : false;
    },
    startDate: {
      get() {
        return this.defaultStartDate;
      },
      set(value) {
        this.$emit('startDate', value);
      }
    },
    endDate: {
      get() {
        return this.defaultEndDate;
      },
      set(value) {
        this.$emit('endDate', value);
      }
    }
  }
};
</script>

<style>
.date-select {
  display: flex;
}

.date-select__start-date,
.date-select__end-date {
  margin-right: 1rem;
}

.date-select.compact .date-select__label {
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
}

.date-select button {
  padding: 0.5rem 0.9rem;
}

.date-select button:focus {
  box-shadow: none;
}

.date-select .form-control {
  font-size: 0.875rem;
}

.date-select .b-form-btn-label-control.form-control {
  height: 43px;
  min-width: 11.25rem;
  border-radius: 5px;
}
</style>
